<template>
  <div class="ModifyOrArchiveMission">
    <CModal
      :show.sync="isMissionModified"
      :no-close-on-backdrop="true"
      title="Mission modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre mission a bien été modifiée 👍
      </p>
      <p>
        Vous pouvez continuer les modifications ou retourner au tableau de bord de votre mission.
      </p>
      <template #footer>
        <CButton @click="$router.push('/mission/'+$route.params.id)" color="primary">Retourner au tableau de bord de votre mission</CButton>
        <CButton @click="isMissionModified = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isMissionArchived"
      :no-close-on-backdrop="true"
      title="Mission archivée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre mission a bien été archivée ⛔
      </p>
      <p>
        Elle n'apparaîtra plus dans les missions de vos collaborateurs et aucune modification ne pourra plus y être apportée.
        Vous pouvez retrouver toutes les missions archivées dans la rubrique "Archives - Missions archivées"
      </p>
      <template #footer>
        <CButton @click="$router.push('/mission/'+$route.params.id)" color="primary">Retourner au tableau de bord de votre mission</CButton>
        <CButton @click="isMissionArchived = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isMissionActivated"
      :no-close-on-backdrop="true"
      title="Mission réactivée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre mission a bien été réactivée 🥳
      </p>
      <p>
        Tous les collaborateurs associés la verront dorénavant dans leur liste de missions et pourront
        créer des priorités, factures, etc.
      </p>
      <template #footer>
        <CButton @click="$router.push('/mission/'+$route.params.id)" color="primary">Retourner au tableau de bord de votre mission</CButton>
        <CButton @click="isMissionActivated = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CCard v-if="!mission.is_active">
      <CCardHeader>
        <slot name="header">
          <strong>Votre mission a été archivée</strong>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CButton
          :color="activateMissionButtonStyle"
          shape="pill" block class="px-4" size="lg"
          @click='activateMission'
          :disabled="activateMissionInProcess">
          <CSpinner size="sm" label="archive client spinner" v-if="activateMissionInProcess"></CSpinner>
          {{ activateMissionButtonText }}
        </CButton>
      </CCardBody>
    </CCard>

    <CRow>
      <CCol sm="6">
        <h1> {{mission.client.name}} - {{mission.name}} </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('/mission/'+$route.params.id)"
          size="sm"
          shape="pill"
          color="outline-dark">
            <CIcon name="cil-arrow-left"/> Retour au tableau de bord mission
        </CButton>
      </CCol>
      <CCol>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <span> Client actuel associé : </span>
            <strong> {{actualMissionClientName}} </strong>

            <vue-bootstrap-typeahead
              class="mt-2"
              v-model="modifyMissionClientName"
              :data="clients"
              :serializer="item => item.name"
              @hit="modifyMissionClientObject = $event"
               placeholder="Chercher votre client par nom"
            />
          </CCol>
          <CCol sm="6">
            <span> Référent actuel de la mission : </span>
            <strong> {{actualMissionReferentFullName}} </strong>

            <vue-bootstrap-typeahead
              class="mt-2"
              v-model="modifyMissionReferentName"
              :data="referents"
              :serializer="item => item.full_name"
              @hit="modifyMissionReferentObject = $event"
               placeholder="Chercher votre collaborateur par nom ou prénom"
            />
          </CCol>
        </CRow>



        <CRow class="mt-3">
          <CCol sm="5">
            Souhaitez-vous également affecter un contact de votre base à votre mission ?
          </CCol>
          <CCol sm="7">
            <CInputRadioGroup
              :checked.sync="wantsToAddContact"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <div class="mt-2" v-if="wantsToAddContact == 'true'">
          <span> Contact actuel choisi : </span>
          <strong v-if="actualMissionContactFullName != ''"> {{actualMissionContactFullName}} </strong>
          <strong v-else> Aucun </strong>

          <vue-bootstrap-typeahead
            class="mt-2"
            v-model="modifyMissionContactName"
            :data="contacts"
            :serializer="item => item.full_name_email"
            @hit="modifyMissionContactObject = $event"
             placeholder="Chercher votre contact par nom ou prénom"
          />
        </div>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Caractéristiques de la mission</strong>
      </CCardHeader>
      <CCardBody>
        <CInput
          label="Nom de la mission"
          v-model="modifyMissionName" type="text"
          maxlength="150"
          @input="$v.modifyMissionName.$touch()"
          :isValid="$v.modifyMissionName.$dirty ? !$v.modifyMissionName.$error : null"
          :disabled="!mission.is_active"
          invalid-feedback="Le nom doit comporter entre 2 et 30 caractères"
        >
        </CInput>

        <div role="group" class="form-group">
          <label for="modify-mission-types-input">Type(s) de la mission</label>
          <vue-multiselect-input
            id="modify-mission-types-input"
            name="modify-mission-types-input"
            v-model="modifyMissionTypes"
            :options="missionsSelectOptions"
            :multiple="true"
            :group-select="true"
            placeholder="Choisissez un ou plusieurs types de mission"
            selectLabel="Ajouter"
            deselectLabel="Supprimer"
            selectedLabel="Sélectionné"
            track-by="value"
            label="label"
            :disabled="!mission.is_active">
            <span slot="noResult">Aucun élément trouvé, veuillez réessayer</span>
            <span slot="noOptions">Aucun type de mission</span>
          </vue-multiselect-input>
        </div>
        <small v-if="!isMissionTypeActive" class="text-info">
          Attention, un type de mission associé à cette mission a été désactivé.
          Pour modifier cette mission, choisissez un type de mission actif.
        </small>

        <CRow class="mb-2">
          <CCol>
            Mission facturée
          </CCol>
        </CRow>
        <CRow class="mb-2">
          <CCol>
            <CInputRadioGroup
              :checked.sync="modifyMissionIsBilled"
              :custom="true"
              :inline="true"
              :disabled="!mission.is_active"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <CRow v-if="modifyMissionIsBilled == 'true'" class="mb-2">
          <CCol>
            <CRow>
              <CCol tag="label">
                Type de contrat
              </CCol>
            </CRow>
            <CRow>
              <CCol class="form-inline">
                <CInputCheckbox
                  label="Honoraires"
                  :custom="true"
                  name="modify-mission-input-honoraires"
                  :checked.sync="modifyMissionIsHonoraires"
                  :disabled="!mission.is_active"
                  :inline="true"
                />
                <CInputCheckbox
                  label="Honoraires de résultats"
                  :custom="true"
                  name="modify-mission-input-honoraires-resultats"
                  :checked.sync="modifyMissionIsHonorairesResultats"
                  :inline="true"
                />
                <CInputCheckbox
                  label="Régie plafonnée"
                  :custom="true"
                  name="modify-mission-input-regie"
                  :checked.sync="modifyMissionIsRegiePlafonnee"
                  :disabled="!mission.is_active"
                  :inline="true"
                />
                <CInputCheckbox
                  label="Forfait"
                  :custom="true"
                  name="modify-mission-input-forfait"
                  :checked.sync="modifyMissionIsForfait"
                  :disabled="!mission.is_active"
                  :inline="true"
                />
                <CInputCheckbox
                  label="AJ"
                  :custom="true"
                  name="modify-mission-input-aj"
                  :checked.sync="modifyMissionIsAJ"
                  :disabled="!mission.is_active"
                  :inline="true"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol v-if="modifyMissionIsHonoraires || modifyMissionIsRegiePlafonnee || modifyMissionIsForfait" sm="6">
            <CInput
              label="Montant de la mission (facultatif)"
              v-model="modifyMissionMontantHT" type="number"
              maxlength="50"
              placeholder="Montant (en € HT)"
              @input="$v.modifyMissionMontantHT.$touch()"
              :isValid="$v.modifyMissionMontantHT.$dirty ? !$v.modifyMissionMontantHT.$error : null"
              :disabled="!mission.is_active"
              min="0" max="100000000" step=".01" @blur="modifyMissionMontantHTCorrection"
            >
            </CInput>
          </CCol>
          <CCol v-if="modifyMissionIsHonorairesResultats" sm="6">
            <CInput
              prepend="%"
              label="Pourcentage du résultat (facultatif)"
              v-model="modifyMissionResultatsPercentage" type="number"
              placeholder="Renseignez le pourcentage de résultat"
              @input="$v.modifyMissionResultatsPercentage.$touch()"
              :isValid="$v.modifyMissionResultatsPercentage.$dirty ? !$v.modifyMissionResultatsPercentage.$error : null"
              min="0" max="100" step="1"
            >
            </CInput>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="6">
            <div v-if="$browserDetect.isSafari">
              <b-form-group id="modify-mission-date_debut-group" label="Date de début de la mission" label-for="modify-mission-date_debut-input">
                <b-form-datepicker
                  id="modify-mission-date_debut-input"
                  name="modify-mission-date_debut-input"
                  locale="fr"
                  :disabled="!mission.is_active"
                  placeholder="Choisissez une date"
                  v-model="modifyMissionStartingDate" class="mb-2"
                  @input="$v.modifyMissionStartingDate.$touch()"
                  :state="$v.modifyMissionStartingDate.$dirty ? !$v.modifyMissionStartingDate.$error : null">
                </b-form-datepicker>
                <small class="text-danger" v-if="!$v.modifyMissionStartingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </b-form-group>
            </div>
            <div v-else>
              <CInput
                label="Date de début de la mission"
                v-model="modifyMissionStartingDate" type="date"
                placeholder="AAAA-MM-DD"
                @input="$v.modifyMissionStartingDate.$touch()"
                :isValid="$v.modifyMissionStartingDate.$dirty ? !$v.modifyMissionStartingDate.$error : null"
                :disabled="!mission.is_active"
                invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
              >
              <template #description>
                <div v-if="$browserDetect.isIE" class="text-info">
                  <small>Vous utilisez Internet Explorer, veuillez entrer votre
                  date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                </div>
                <small class="text-danger" v-if="!$v.modifyMissionStartingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </template>
              </CInput>
            </div>


          </CCol>
          <CCol sm="6">

            <div v-if="$browserDetect.isSafari">
              <b-form-group id="modify-mission-date_fin-group" label="Date de fin de la mission (facultatif)" label-for="modify-mission-date_fin-input">
                <b-form-datepicker
                  id="modify-mission-date_fin-input"
                  name="modify-mission-date_fin-input"
                  locale="fr"
                  placeholder="Choisissez une date"
                  :disabled="!mission.is_active"
                  v-model="modifyMissionEndingDate" class="mb-2"
                  @input="$v.modifyMissionEndingDate.$touch()"
                  :state="$v.modifyMissionEndingDate.$dirty ? !$v.modifyMissionEndingDate.$error : null">
                </b-form-datepicker>
                <small class="text-danger" v-if="!$v.modifyMissionEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </b-form-group>
            </div>
            <div v-else>
              <CInput
                label="Date de fin de la mission (facultatif)"
                v-model="modifyMissionEndingDate" type="date"
                placeholder="AAAA-MM-DD"
                @input="$v.modifyMissionEndingDate.$touch()"
                :isValid="$v.modifyMissionEndingDate.$dirty ? !$v.modifyMissionEndingDate.$error : null"
                :disabled="!mission.is_active"
                invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
              >
              <template #description>
                <div v-if="$browserDetect.isIE" class="text-info">
                  <small>Vous utilisez Internet Explorer, veuillez entrer votre
                  date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                </div>
                <small class="text-danger" v-if="!$v.modifyMissionEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </template>
              </CInput>
            </div>

          </CCol>
        </CRow>

        <CInput
          label="Numéro RG (facultatif)"
          v-model="modifyMissionRGNumber" type="text"
          maxlength="49"
          placeholder="Référence"
          @input="$v.modifyMissionRGNumber.$touch()"
          :isValid="$v.modifyMissionRGNumber.$dirty ? !$v.modifyMissionRGNumber.$error : null"
          invalid-feedback="La référence doit comporter entre 2 et 50 caractères"
        >
        </CInput>

        <CTextarea
          label="Décrivez brièvement le contenu de la mission"
          v-model="modifyMissionDescription" type="text"
          rows="3"
          maxlength="999"
          @input="$v.modifyMissionDescription.$touch()"
          :isValid="$v.modifyMissionDescription.$dirty ? !$v.modifyMissionDescription.$error : null"
          :disabled="!mission.is_active"
        >
        </CTextarea>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Partie adverse</strong>
      </CCardHeader>
      <CCardBody>

        <CRow class="mb-2">
          <CCol sm="4">
            La partie adverse est
          </CCol>
          <CCol sm="8">
            <CInputRadioGroup
              :checked.sync="modifyMissionContreClientIsFirm"
              :custom="true"
              :disabled="!mission.is_active"
              :inline="true"
              :options='clientIsFirmArrayForm'
            />
          </CCol>
        </CRow>
        <div v-if="modifyMissionContreClientIsFirm =='true'">
          <CRow >
            <CCol sm="6">
              <CInput
                label="Siren de la partie adverse"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="modifyMissionContreClientSiren" type="text"
                maxlength="9"
                :disabled="!mission.is_active"
                placeholder="XXXXXXXXX"
                @input="$v.modifyMissionContreClientSiren.$touch()"
                :isValid="$v.modifyMissionContreClientSiren.$dirty ? !$v.modifyMissionContreClientSiren.$error : null"
                invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
              label="Nom de la partie adverse"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="modifyMissionContreClientName" type="text"
              maxlength="150"
              :disabled="!mission.is_active"
              placeholder="Nom de la partie adverse"
              @input="$v.modifyMissionContreClientName.$touch()"
              :isValid="$v.modifyMissionContreClientName.$dirty ? !$v.modifyMissionContreClientName.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

        </div>

        <div v-if="modifyMissionContreClientIsFirm =='false'">
          <CRow >
            <CCol sm="6">
              <CInput
                label="Prénom"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="modifyMissionContreClientFirstName" type="text"
                maxlength="150"
                :disabled="!mission.is_active"
                placeholder="Prénom"
                @input="$v.modifyMissionContreClientFirstName.$touch()"
                :isValid="$v.modifyMissionContreClientFirstName.$dirty ? !$v.modifyMissionContreClientFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Nom"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="modifyMissionContreClientLastName" type="text"
                maxlength="150"
                :disabled="!mission.is_active"
                placeholder="Nom"
                @input="$v.modifyMissionContreClientLastName.$touch()"
                :isValid="$v.modifyMissionContreClientLastName.$dirty ? !$v.modifyMissionContreClientLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>
        </div>

        <CRow>
          <CCol sm="6">
            <CInput
              label="Nom de l'avocat adverse"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="modifyMissionContreAvocatName" type="text"
              maxlength="150"
              :disabled="!mission.is_active"
              placeholder="Nom"
              @input="$v.modifyMissionContreAvocatName.$touch()"
              :isValid="$v.modifyMissionContreAvocatName.$dirty ? !$v.modifyMissionContreAvocatName.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
            >
            </CInput>
          </CCol>
          <CCol sm="6">
            <CInput
              label="Email de l'avocat adverse"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="modifyMissionContreAvocatEmail" type="email"
              maxlength="150"
              :disabled="!mission.is_active"
              placeholder="Nom"
              @input="$v.modifyMissionContreAvocatEmail.$touch()"
              :isValid="$v.modifyMissionContreAvocatEmail.$dirty ? !$v.modifyMissionContreAvocatEmail.$error : null"
              invalid-feedback="Veuillez rentrer un email valide"
            >
            </CInput>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol sm="12">
            <div role="group" class="form-group">
              <label for="addMissionContreAvocattVuePhoneNumber" class>
                Numéro de téléphone (facultatif)
              </label>
              <vue-phone-number-input
                id="addMissionContreAvocattVuePhoneNumber"
                name="addMissionContreAvocattVuePhoneNumber"
                v-model="modifyMissionContreAvocatPhoneNumber"
                :disabled="!mission.is_active"
                :default-country-code='modifyMissionContreAvocatPhoneNumberResult.countryCode'
                :preferred-countries="['FR', 'BE', 'DE']"
                valid-color='#28a745'
                error-color='#dc3545'
                :translations="{
                  countrySelectorLabel: 'Code pays',
                  countrySelectorError: 'Choisir un pays',
                  phoneNumberLabel: 'Numéro de téléphone',
                  example: 'Exemple :'
                }"
                @update='modifyMissionContreAvocatPhoneNumberResult = $event'
                :required='false'
              />
              <small class="text-danger" v-if="modifyMissionContreAvocatPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>


    <CRow v-if="mission.is_active" class="mb-4">
      <CCol sm="6">
        <CButton
          :color="modifyMissionButtonStyle"
          shape="pill" block class="px-4"
          size="lg"
          @click='modifyMission'
          :disabled="isInvalidAddMission || modifyMissionInProcess">
          <CSpinner size="sm" label="modify mission spinner" v-if="modifyMissionInProcess"></CSpinner>
          {{ modifyMissionButtonText }}
        </CButton>
      </CCol>
      <CCol sm="6">
        <CButton
          :color="archiveMissionButtonStyle"
          shape="pill" block class="px-4"
          size="lg"
          @click='archiveMission'
          :disabled="archiveMissionInProcess">
          <CSpinner size="sm" label="archive mission spinner" v-if="archiveMissionInProcess"></CSpinner>
          {{ archiveMissionButtonText }}
        </CButton>
        <small class="text-danger">Attention, si vous archivez cette mission, aucun collaborateur ne pourra plus la modifier</small>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue, maxValue, integer, numeric, email} from 'vuelidate/lib/validators'
import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'
import { isDate, isDateOrBlank } from '@/validators/validators'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()


export default {
  name: 'ModifyOrArchiveMission',
  components: {
    Loading,
    VueBootstrapTypeahead
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      isMissionLoading: false,
      mission: {
        id: 0,
        name: '',
        client: '',
        types: [],
        state: '',
        is_billed: 'true',
        is_honoraires: false,
        is_honoraires_resultats: false,
        is_regie_plafonnee: false,
        is_forfait: false,
        is_aj: false,
        montant_ht: '',
        resultats_percentage: '',
        starting_date: '',
        ending_date: '',
        rg_number: '',
        description: '',

        contre_client_is_firm: 'true',

        contre_client_siren: '',
        contre_client_name: '',
        contre_client_first_name: '',
        contre_client_last_name: '',

        contre_avocat_name: '',
        contre_avocat_email: '',
        contre_avocat_phone_country_code: '',
        contre_avocat_phone_number: '',

        is_active: '',
        created_at: '',

        contact: {
          id: 0,
          first_name: '',
          last_name: '',
          full_name: '',
        },
        referent: {
          id: 0,
          full_name: '',
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        collaborateurs: []
      },
      actualMissionClientName: '',
      actualMissionReferentFullName: '',
      actualMissionContactFullName: '',

      // --------- MISSION TYPE -----------
      isMissionTypesLoading: false,
      missionTypes: [],
      missionsSelectOptions: [],

      // ----------- MODIFY MISSION --------------
      isMissionModified: false,
      modifyMissionName: '',
      modifyMissionTypes: [],
      modifyMissionIsBilled: 'true',
      modifyMissionIsHonoraires: false,
      modifyMissionIsHonorairesResultats: false,
      modifyMissionIsRegiePlafonnee: false ,
      modifyMissionIsForfait: false,
      modifyMissionIsAJ: false,
      modifyMissionMontantHT: '',
      modifyMissionResultatsPercentage: '',
      modifyMissionStartingDate: '',
      modifyMissionEndingDate: '',
      modifyMissionRGNumber: '',
      modifyMissionDescription: '',

      modifyMissionContreClientIsFirm: 'true',
      clientIsFirmArrayForm: [
        { label: 'Une personne morale', value: 'true' },
        { label: "Une personne physique", value: 'false' },
      ],

      modifyMissionContreClientSiren: '',
      modifyMissionContreClientName: '',
      modifyMissionContreClientFirstName: '',
      modifyMissionContreClientLastName: '',
      modifyMissionContreAvocatName: '',
      modifyMissionContreAvocatEmail: '',
      modifyMissionContreAvocatPhoneNumber: null,
      modifyMissionContreAvocatPhoneNumberResult: {
        countryCode: "FR",
      },

      clients: [],
      modifyMissionClientName: '',
      modifyMissionClientObject: {
        id: '',
        name: ''
      },

      referents: [],
      modifyMissionReferentName: '',
      modifyMissionReferentObject: {
        id: '',
        full_name: ''
      },

      wantsToAddContact: 'false',
      contacts: [],
      modifyMissionContactName: '',
      modifyMissionContactObject: {
        id: '',
        full_name: ''
      },

      booleanArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: 'Non', value: 'false' },
      ],

      modifyMissionButtonText: 'Modifier votre mission',
      modifyMissionButtonStyle: 'outline-primary',
      modifyMissionInProcess: false,

      // ------------- ARCHIVE MISSION -------------
      archiveMissionInProcess: false,
      archiveMissionButtonText: "Archiver la mission",
      archiveMissionButtonStyle: 'outline-danger',
      isMissionArchived: false,

      // ------------- ACTIVATE MISSION -------------
      activateMissionInProcess: false,
      activateMissionButtonText: "Réactiver la mission",
      activateMissionButtonStyle: 'primary',
      isMissionActivated: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isMissionLoading || this.isMissionTypesLoading) {
        return true
      }
      return false
    },
    authHeaders () {
      return apiUserConnected.getAuthHeaders(this.token)
    },
    isMissionTypeActive () {
      for (var i = 0; i < this.mission.types.length; i++) {
        if (!this.mission.types[i].is_active) {
          return false
        }
      }
      return true
    },
    isInvalidAddMission () {
      if (this.$v.modifyMissionContactObject.$invalid || this.$v.modifyMissionClientObject.$invalid ||
      this.$v.modifyMissionReferentObject.$invalid || this.$v.modifyMissionName.$invalid ||
      this.$v.modifyMissionTypes.$invalid || this.$v.modifyMissionIsBilled.$invalid || this.$v.modifyMissionMontantHT.$invalid || this.$v.modifyMissionResultatsPercentage.$invalid ||
      this.$v.modifyMissionStartingDate.$invalid || this.$v.modifyMissionEndingDate.$invalid || this.$v.modifyMissionRGNumber.$invalid || this.$v.modifyMissionDescription.$invalid || this.$v.modifyMissionContreClientSiren.$invalid ||
      this.$v.modifyMissionContreClientName.$invalid || this.$v.modifyMissionContreClientFirstName.$invalid || this.$v.modifyMissionContreClientLastName.$invalid || this.$v.modifyMissionContreAvocatName.$invalid ||
      this.$v.modifyMissionContreAvocatEmail.$invalid || this.$v.modifyMissionContreAvocatPhoneNumberResult.$invalid ) {
        return true
      }
      return false
    },

    modifyMissionTypesIdArray () {
      var final_array = []
      for (var i = 0; i < this.modifyMissionTypes.length; i++) {
        final_array.push(
          this.modifyMissionTypes[i].value,
        )
      }
      return final_array
    }
  },
  validations: {
    modifyMissionContactObject: {
      id: {
        integer
      }
    },
    modifyMissionClientObject: {
      id: {
        required,
        integer
      }
    },
    modifyMissionReferentObject: {
      id: {
        required,
        integer
      }
    },
    modifyMissionName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    modifyMissionTypes: {
      required,
      isNotEmptyArray (value) {
        if (value.length > 0) {
          return true
        }
        else {
          return false
        }
      }
    },
    modifyMissionIsBilled: {
      required
    },
    modifyMissionIsHonoraires: {
      required
    },
    modifyMissionIsHonorairesResultats: {
      required
    },
    modifyMissionIsRegiePlafonnee: {
      required
    },
    modifyMissionIsForfait: {
      required
    },
    modifyMissionIsAJ: {
      required
    },
    modifyMissionMontantHT: {
      minValue: minValue(1),
      maxValue: maxValue(100000000)
    },
    modifyMissionResultatsPercentage: {
      integer,
      minValue: minValue(0),
      maxValue: maxValue(100)
    },
    modifyMissionStartingDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyMissionEndingDate: {
      validDate(value) {
        return isDateOrBlank(value)
      }
    },
    modifyMissionRGNumber: {
      minLength: minLength(2),
      maxLength: maxLength(49)
    },
    modifyMissionDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },

    // CONTRE INFORMATIONS
    modifyMissionContreClientSiren: {
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric,
    },
    modifyMissionContreClientName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyMissionContreClientFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyMissionContreClientLastName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },

    modifyMissionContreAvocatName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyMissionContreAvocatEmail: {
      email,
      minLength: minLength(2),
      maxLength: maxLength(249),
    },
    modifyMissionContreAvocatPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          if (this.modifyMissionContreAvocatPhoneNumber == null) {
            return true
          }
          else {
            return value
          }
        }
      }
    },
  },

  created: function() {
    this.getMission()
    this.getAllMissionTypes()
  },

  watch: {
    missionTypes: function (missionTypesArray) {
      if (missionTypesArray.length == 0) {
        this.missionsSelectOptions = []
      }
      else {
        var final_array = []
        for (var i = 0; i < missionTypesArray.length; i++) {
          final_array.push(
            {
              'label': missionTypesArray[i].name,
              'value': missionTypesArray[i].id,
            }
          )
        }
        this.missionsSelectOptions = final_array
      }
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    modifyMissionClientName(newQuery) {
      if (newQuery == '') {
        this.clients = []
        this.modifyMissionClientObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.clients = res.data
        })
      }
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    modifyMissionReferentName(newQuery) {
      if (newQuery == '') {
        this.referents = []
        this.modifyMissionReferentObject = {
          id: '',
          full_name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchCollaborateursByFirstNameOrLastName(this.token, newQuery)
        .then((res) => {
          this.referents = res.data
        })
      }
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    modifyMissionContactName(newQuery) {
      if (newQuery == '') {
        this.contacts = []
        this.modifyMissionContactObject = {
          id: '',
          full_name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchContactByFirstOrLastName(this.token, newQuery)
        .then((res) => {
          this.contacts = res.data
        })
      }
    }
  },

  methods: {
    // ----------- GLOBALS -----------------
    modifyMissionMontantHTCorrection () {
      var floatModifyMissionHT = parseFloat(this.modifyMissionMontantHT)
      if (isNaN(floatModifyMissionHT)) {
        this.modifyMissionMontantHT = ''
      }
      else {
        this.modifyMissionMontantHT = floatModifyMissionHT.toFixed(2)
      }
    },

    // ------------- Getters --------------
    getMission () {
      this.isMissionLoading = true
      apiUserConnected.getMission(this.token, this.$route.params.id)
      .then((result) => {
        this.mission = result.data

        if (this.mission.contact) {
          this.modifyMissionContactObject = {
            id: this.mission.contact.id,
            full_name: this.mission.contact.full_name
          }
          this.modifyMissionContactName = this.mission.contact.full_name
          this.actualMissionContactFullName = this.mission.contact.full_name
          this.wantsToAddContact = String(true)
        }
        else {
          this.modifyMissionContactObject = {
            id: '',
            full_name: ''
          }
          this.modifyMissionContactName = ''
          this.actualMissionContactFullName = ''
          this.wantsToAddContact = String(false)
        }
        this.modifyMissionClientObject = {
          id: this.mission.client.id,
          name: this.mission.client.name
        }
        this.modifyMissionClientName = this.mission.client.name
        this.actualMissionClientName = this.mission.client.name

        this.modifyMissionReferentObject = {
          id: this.mission.referent.id,
          name: this.mission.referent.full_name
        }
        this.modifyMissionReferentFullName = this.mission.referent.full_name
        this.actualMissionReferentFullName = this.mission.referent.full_name

        this.modifyMissionName = this.mission.name

        this.modifyMissionTypes = []
        for (var i = 0; i < this.mission.types.length; i++) {
          this.modifyMissionTypes.push(
            {
              'label': this.mission.types[i].name,
              'value': this.mission.types[i].id,
            }
          )
        }

        this.modifyMissionIsBilled = String(this.mission.is_billed)
        this.modifyMissionIsHonoraires = this.mission.is_honoraires
        this.modifyMissionIsHonorairesResultats = this.mission.is_honoraires_resultats
        this.modifyMissionIsRegiePlafonnee = this.mission.is_regie_plafonnee
        this.modifyMissionIsForfait = this.mission.is_forfait
        this.modifyMissionIsAJ = this.mission.is_aj
        if (this.mission.montant_ht) {
          this.modifyMissionMontantHT = this.mission.montant_ht
        }
        else {
          this.modifyMissionMontantHT = ''
        }
        if (this.mission.resultats_percentage) {
          this.modifyMissionResultatsPercentage = this.mission.resultats_percentage
        }
        else {
          this.modifyMissionResultatsPercentage = ''
        }
        this.modifyMissionStartingDate = this.mission.starting_date
        if (this.mission.ending_date) {
          this.modifyMissionEndingDate = this.mission.ending_date
        }
        else {
          this.modifyMissionEndingDate = ''
        }
        this.modifyMissionRGNumber = this.mission.rg_number
        this.modifyMissionDescription = this.mission.description

        // CONTRE INFORMATIONS
        this.modifyMissionContreClientIsFirm = String(this.mission.contre_client_is_firm)
        this.modifyMissionContreClientSiren = this.mission.contre_client_siren
        if (this.mission.contre_client_name == ' ') {
          this.modifyMissionContreClientName = ''
        }
        else {
          this.modifyMissionContreClientName = this.mission.contre_client_name
        }
        this.modifyMissionContreClientFirstName = this.mission.contre_client_first_name
        this.modifyMissionContreClientLastName = this.mission.contre_client_last_name
        this.modifyMissionContreAvocatName = this.mission.contre_avocat_name
        this.modifyMissionContreAvocatEmail = this.mission.contre_avocat_email
        if (this.mission.contre_avocat_phone_number != '') {
          this.modifyMissionContreAvocatPhoneNumberResult = {
            countryCode: this.mission.contre_avocat_phone_country_code,
            isValid: true,
            nationalNumber: this.mission.contre_avocat_phone_number
          }
          this.modifyMissionContreAvocatPhoneNumber = this.mission.contre_avocat_phone_number
        }
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionLoading = false
      })
    },

    getAllMissionTypes () {
      this.isMissionTypesLoading = true
      apiUserConnected.getAllMissionTypes(this.token)
      .then((result) => {
        this.missionTypes = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypesLoading = false
      })
    },

    // --------------- MODIFY MISSION ----------------

    modifyMission () {
      this.modifyMissionInProcess = true
      this.modifyMissionButtonText = "Modification en cours"
      this.modifyMissionButtonStyle = 'secondary'
      apiManagerConnected.modifyMission(this.token, this.$route.params.id,
        this.modifyMissionContactObject.id, this.modifyMissionClientObject.id, this.modifyMissionReferentObject.id, this.modifyMissionName,
        this.modifyMissionTypesIdArray, this.modifyMissionIsBilled, this.modifyMissionIsHonoraires, this.modifyMissionIsHonorairesResultats, this.modifyMissionIsRegiePlafonnee,
        this.modifyMissionIsForfait, this.modifyMissionIsAJ, this.modifyMissionMontantHT, this.modifyMissionResultatsPercentage, this.modifyMissionStartingDate,
        this.modifyMissionEndingDate, this.modifyMissionRGNumber, this.modifyMissionDescription, this.modifyMissionContreClientIsFirm, this.modifyMissionContreClientSiren, this.modifyMissionContreClientName,
        this.modifyMissionContreClientFirstName, this.modifyMissionContreClientLastName, this.modifyMissionContreAvocatName, this.modifyMissionContreAvocatEmail,
        this.modifyMissionContreAvocatPhoneNumberResult.countryCode, this.modifyMissionContreAvocatPhoneNumberResult.nationalNumber,)
      .then(() => {
        this.getMission()
        this.isMissionModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyMissionInProcess = false
        this.modifyMissionButtonText = "Modifier la mission"
        this.modifyMissionButtonStyle = 'primary'
      })
    },

    // --------------- DELETE MISSION ----------------

    archiveMission () {
      this.archiveMissionInProcess = true
      this.archiveMissionButtonText = "Archivage en cours"
      this.archiveMissionButtonStyle = 'secondary'
      apiManagerConnected.archiveMission(this.token, this.$route.params.id)
      .then(() => {
        this.getMission()
        this.isMissionArchived = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.archiveMissionInProcess = false
        this.archiveMissionButtonText = "Archiver la mission"
        this.archiveMissionButtonStyle = 'danger'
      })
    },

    // --------------- ACTIVATE MISSION ----------------

    activateMission () {
      this.activateMissionInProcess = true
      this.activateMissionButtonText = "Activation en cours"
      this.activateMissionButtonStyle = 'secondary'
      apiManagerConnected.activateMission(this.token, this.$route.params.id)
      .then(() => {
        this.getMission()
        this.isMissionActivated = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.activateMissionInProcess = false
        this.activateMissionButtonText = "Réactiver la mission"
        this.activateMissionButtonStyle = 'primary'
      })
    },
  }
}
</script>
